<template>
  <div class="antialiased dark:bg-gray-100 dark:text-gray-800">
    <MainHero />

    <AboutUs />
    <TimeLine />
    <QuestTracker />

    <FaqSection />

    <CallToAction />

  </div>
</template>

<script>
import MainHero from "@/components/MainHero.vue";
import TimeLine from "@/components/TimeLine.vue";
import AboutUs from "@/components/AboutUs.vue";
import FaqSection from "@/components/FaqSection.vue";
import CallToAction from "@/components/CallToAction.vue";
import QuestTracker from "@/components/quest/QuestTracker.vue";

export default {
  name: "HomeView",
  components: {FaqSection, AboutUs, TimeLine, MainHero, CallToAction, QuestTracker },
};
</script>
