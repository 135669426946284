<template>
  <section class="bg-gray-800 text-gray-200 py-12">
    <div class="container mx-auto px-4 max-w-5xl">
      <h2 class="text-3xl font-bold text-center mb-6">Quest Tracker</h2>
      <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <div class="flex flex-col items-center justify-center bg-gray-800 text-gray-400 p-8 rounded-lg">
          <div class="text-6xl font-bold">?</div>
          <p class="mt-4 text-lg text-center">This competition hasn’t started yet. Stay tuned for the announcement!</p>
        </div>
        <div class="flex flex-col items-center justify-center bg-gray-800 text-gray-400 p-8 rounded-lg">
          <div class="text-6xl font-bold">?</div>
          <p class="mt-4 text-lg text-center">This competition hasn’t started yet. Stay tuned for the announcement!</p>
        </div>
        <div class="flex flex-col items-center justify-center bg-gray-800 text-gray-400 p-8 rounded-lg">
          <div class="text-6xl font-bold">?</div>
          <p class="mt-4 text-lg text-center">This competition hasn’t started yet. Stay tuned for the announcement!</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

</script>