<template>
  <!-- Staking Section -->
  <section class="relative flex flex-col items-center justify-center bg-gray-900 text-white px-4 py-36">
    <!-- Dark Background -->
    <div class="absolute inset-0 dark:bg-gray-900 dark:text-gray-200 py-12"></div>

    <!-- Content -->
    <div class="relative z-10 max-w-5xl w-full text-center">
      <h2 class="text-3xl sm:text-4xl font-bold mb-4 text-emerald-400">Staking Dashboard</h2>
      <p class="text-lg sm:text-xl mb-8 text-gray-300">
        Stake your tokens and earn rewards! Exciting pools are coming soon. Prepare for the next step in your journey.
      </p>

      <!-- Staking Pools UI -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 place-items-center">
        <!-- Pool 1 -->
        <div class="relative w-56 h-72 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-4 p-6">
          <div class="flex items-center justify-center w-16 h-16 bg-emerald-500 rounded-full text-gray-900 font-bold text-3xl">
            ?
          </div>
          <h3 class="text-xl font-semibold text-emerald-400">Pool 1</h3>
          <p class="text-sm text-gray-400">Reward details coming soon</p>
          <button
              class="px-4 py-2 bg-emerald-500 text-gray-900 font-semibold rounded-md cursor-not-allowed"
              disabled
          >
            Coming Soon
          </button>
        </div>

        <!-- Pool 2 -->
        <div class="relative w-56 h-72 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-4 p-6">
          <div class="flex items-center justify-center w-16 h-16 bg-emerald-500 rounded-full text-gray-900 font-bold text-3xl">
            ?
          </div>
          <h3 class="text-xl font-semibold text-emerald-400">Pool 2</h3>
          <p class="text-sm text-gray-400">Reward details coming soon</p>
          <button
              class="px-4 py-2 bg-emerald-500 text-gray-900 font-semibold rounded-md cursor-not-allowed"
              disabled
          >
            Coming Soon
          </button>
        </div>

        <!-- Pool 3 -->
        <div class="relative w-56 h-72 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-4 p-6">
          <div class="flex items-center justify-center w-16 h-16 bg-emerald-500 rounded-full text-gray-900 font-bold text-3xl">
            ?
          </div>
          <h3 class="text-xl font-semibold text-emerald-400">Pool 3</h3>
          <p class="text-sm text-gray-400">Reward details coming soon</p>
          <button
              class="px-4 py-2 bg-emerald-500 text-gray-900 font-semibold rounded-md cursor-not-allowed"
              disabled
          >
            Coming Soon
          </button>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {
  name: "StakingView",
};
</script>
