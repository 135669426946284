<template>
  <!-- Links -->
  <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 py-16">
    <!-- Telegram -->
    <a
        href="https://t.me/TheChosen1VerificationPortal"
        target="_blank"
        class="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition rounded-lg shadow-lg p-6 text-center"
    >
      <div class="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center mb-4">
        <img
            src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
            alt="Telegram"
            class="w-8 h-8"
        />
      </div>
      <h3 class="text-lg font-semibold text-gray-200">Telegram</h3>
      <p class="text-sm text-gray-400">Join the discussion!</p>
    </a>

    <!-- Twitter -->
    <a
        href="https://x.com"
        target="_blank"
        class="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition rounded-lg shadow-lg p-6 text-center"
    >
      <div class="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center mb-4">
        <img
            src="@/assets/x.png"
            alt="X"
            class="w-8 h-8"
        />

      </div>
      <h3 class="text-lg font-semibold text-gray-200">X (Twitter)</h3>
      <p class="text-sm text-gray-400">Follow us for updates!</p>
    </a>

    <!-- DexScreener -->
    <a
        href="https://dexscreener.com"
        target="_blank"
        class="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition rounded-lg shadow-lg p-6 text-center"
    >
      <div class="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center mb-4">
        <img
            src="https://cdn-icons-png.flaticon.com/512/3416/3416028.png"
            alt="DexScreener"
            class="w-8 h-8"
        />
      </div>
      <h3 class="text-lg font-semibold text-gray-200">DexScreener</h3>
      <p class="text-sm text-gray-400">Track our performance!</p>
    </a>
  </div>
</template>
<script setup lang="ts">
</script>