<template>
  <div class="bg-dark1 text-green1 min-h-screen">
<!--    <MainHeader />-->
<!--    <router-view />-->
<!--    <FooTer />-->

  </div>
</template>

<script>

export default {
  name: "App",
};
</script>
