<template>
  <section class="dark:bg-gray-100 dark:text-gray-800">
    <div class="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">

      <div class="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
        <h1 class="text-5xl font-extrabold leading-tight sm:text-6xl">
          Are you
          <br>
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-emerald-500 to-emerald-700">
            THE CHOSEN ONE?
          </span>
        </h1>
        <h2 class="text-2xl font-semibold text-emerald-600 mt-4">$CHOSEN1</h2>
        <p class="mt-6 mb-8 text-lg sm:mb-12">
          Crypto cult, amazing community<br>
          <br class="hidden md:inline lg:hidden">
          more than a memecoin
        </p>
        <div class="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
          <p class="text-lg font-medium dark:text-gray-300">
            CA: <span class="font-bold text-emerald-400">TO BE ANNOUNCED</span>
          </p>
        </div>
        <div
            class="flex flex-col mt-8 space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
          <router-link to="/join" class="px-8 py-3 text-lg font-semibold rounded dark:bg-emerald-600 dark:text-gray-50 hover:bg-emerald-700">
            Join Us
          </router-link>

          <a rel="noopener noreferrer" href="#roadmap"
             class="px-8 py-3 text-lg font-semibold rounded dark:bg-emerald-600 dark:text-gray-50 hover:bg-emerald-700">
            Roadmap
          </a>
        </div>
      </div>
      <div class="flex items-center justify-center p-6 m-auto h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
        <img src="@/assets/heroi.webp" alt="" class="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 rounded-xl">
      </div>
    </div>
  </section>
</template>
<script>

</script>