<template>
    <section class="dark:bg-gray-900 dark:text-gray-200 py-36">
      <div class="container mx-auto px-4 max-w-3xl text-center">
        <!-- Title -->
        <h1 class="text-4xl font-bold mb-6 text-emerald-400">Join Us</h1>
        <p class="text-lg mb-8 text-gray-300">
          Become a part of our growing community and take your place among The Chosen Ones.
        </p>

      <LinksComp />
      </div>
    </section>
</template>
<script>

import LinksComp from "@/components/LinksComp.vue";

export default {
  name: "JoinView",
  components: {LinksComp},
};
</script>
