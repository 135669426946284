<template>
  <section id="cta" class="bg-emerald-600 text-white py-12">
    <div class="container mx-auto text-center">
      <h2 class="text-3xl font-bold mb-4">Are You Ready to Embrace Greatness?</h2>
      <p class="text-lg mb-6">Join The Chosen One community today and discover what it means to be truly extraordinary.</p>
      <button class="px-6 py-2 text-lg font-bold bg-gray-900 rounded-lg hover:bg-gray-800">Join Us Now</button>
    </div>
  </section>
</template>
<script>

</script>