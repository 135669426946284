import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import QuestsView from "@/views/QuestsView.vue";
import NftView from "@/views/NftView.vue";
import StakingView from "@/views/StakingView.vue";
import JoinView from "@/views/JoinView.vue";
// import ContactView from "@/views/ContactView.vue";

const routes = [
    { path: "/", name: "home", component: HomeView },
    { path: "/about", name: "about", component: AboutView },
    { path: "/quests", name: "quests", component: QuestsView },
    { path: "/nft", name: "nft", component: NftView },
    { path: "/staking", name: "staking", component: StakingView },
    { path: "/join", name: "join", component: JoinView },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
