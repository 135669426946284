<template>
  <AboutUs/>
  <FaqSection/>
</template>

<script>

import AboutUs from "@/components/AboutUs.vue";
import FaqSection from "@/components/FaqSection.vue";

export default {
  name: "AboutView",
  components: {FaqSection, AboutUs},
};
</script>
