<template>
  <!-- NFT Minting Section -->
  <section class="relative flex flex-col items-center justify-center bg-gray-900 text-white px-4 py-36">
    <!-- Dark Background -->
    <div class="absolute inset-0 dark:bg-gray-900 dark:text-gray-200 py-12"></div>

    <!-- Content -->
    <div class="relative z-10 max-w-4xl w-full text-center">
      <h2 class="text-3xl sm:text-4xl font-bold mb-4 text-emerald-400">NFT Mint</h2>
      <p class="text-lg sm:text-xl mb-8 text-gray-300">
        Discover the mystery! Our exclusive NFT collection is coming soon. Stay tuned to reveal the hidden treasures.
      </p>

      <!-- Placeholder NFT Cards -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 place-items-center">
        <!-- Card 1 -->
        <div class="relative w-48 h-64 bg-gray-800 border-4 border-emerald-500 flex flex-col items-center justify-center rounded-lg shadow-md">
          <span class="text-6xl font-bold text-emerald-400">?</span>
        </div>

        <!-- Card 2 -->
        <div class="relative w-48 h-64 bg-gray-800 border-4 border-emerald-500 flex flex-col items-center justify-center rounded-lg shadow-md">
          <span class="text-6xl font-bold text-emerald-400">?</span>
        </div>

        <!-- Card 3 -->
        <div class="relative w-48 h-64 bg-gray-800 border-4 border-emerald-500 flex flex-col items-center justify-center rounded-lg shadow-md">
          <span class="text-6xl font-bold text-emerald-400">?</span>
        </div>
      </div>

      <!-- Coming Soon Button -->
      <div class="mt-8">
        <button
            class="px-8 py-3 bg-emerald-500 text-gray-900 rounded-md text-lg font-semibold hover:bg-emerald-400 transition"
            disabled
        >
          Coming Soon
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NftView",
};
</script>
