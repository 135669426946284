<template>
  <section id="roadmap" class="dark:bg-gray-100 dark:text-gray-800">
    <div class="container max-w-5xl px-4 py-12 mx-auto">
      <div class="grid gap-4 mx-4 sm:grid-cols-12">
        <div class="col-span-12 sm:col-span-3">
          <div class="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:dark:bg-emerald-600">
            <h3 class="text-3xl font-semibold">The Journey</h3>
            <span class="text-sm font-bold tracking-wider uppercase dark:text-gray-600">Become The Chosen One</span>
          </div>
        </div>
        <div class="relative col-span-12 px-4 space-y-6 sm:col-span-9">
          <div class="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-300">

            <!-- PHASE 1: Awakening -->
            <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-emerald-600">
              <h3 class="text-xl font-semibold tracking-wide">The Awakening</h3>
              <p class="mt-3">
                The journey begins as individuals across the globe embrace their identity as The Chosen Ones.
                Our platform launches with a vibrant, empowering message and tools to help everyone recognize
                their potential and greatness. Join us in celebrating the uniqueness of every individual.
              </p>
            </div>

            <!-- PHASE 2: Building the Fellowship -->
            <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-emerald-600">
              <h3 class="text-xl font-semibold tracking-wide">Building the Fellowship</h3>
              <p class="mt-3">
                • Uniting a global community of Chosen Ones through shared goals and inspiring narratives. <br/>
                • Launch interactive features such as storytelling contests, digital art challenges, and
                collaborative projects. <br/>
                • Foster meaningful discussions and collaborations to empower each member to reach their full potential.
              </p>
            </div>

            <!-- PHASE 3: Expanding Horizons -->
            <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-emerald-600">
              <h3 class="text-xl font-semibold tracking-wide">Expanding Horizons</h3>
              <p class="mt-3">
                • Develop partnerships with inspirational communities and organizations that share our vision. <br/>
                • Introduce innovative tools for personal growth and collective achievements. <br/>
                • Highlight and reward the accomplishments of individuals who exemplify the Chosen One spirit.
              </p>
            </div>

            <!-- PHASE 4: Legacy of The Chosen Ones -->
            <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-emerald-600">
              <h3 class="text-xl font-semibold tracking-wide">Legacy of The Chosen Ones</h3>
              <p class="mt-3">
                • Celebrate the enduring impact of The Chosen One community through storytelling, events, and initiatives. <br/>
                • Empower the next generation to carry forward the mission of self-discovery and excellence. <br/>
                • Continuously adapt and evolve our platform to reflect the collective vision of our members.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
