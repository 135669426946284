<template>
  <div id="faq" class="container max-w-5xl px-4 py-12 mx-auto mb-5">
    <!-- FAQ Section -->
    <div class="mt-16 bg-gray-800 text-gray-200 p-8 rounded-lg">
      <h2 class="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
      <div class="space-y-6 flex-auto">
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">What is The Chosen One?</summary>
          <p class="mt-2">The Chosen One is a movement that celebrates individuality and greatness. It's a platform for creativity, community, and opportunities to grow together.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">How can I join?</summary>
          <p class="mt-2">You can join by clicking the "Join Us" button above and becoming part of our community. Participation in challenges and community events is open to everyone.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">Is The Chosen One free?</summary>
          <p class="mt-2">Yes, being part of The Chosen One is free. Some optional features or events may have costs, but the core community experience is accessible to all.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">What kind of challenges can I expect?</summary>
          <p class="mt-2">Our challenges are designed to inspire creativity and collaboration. They range from art and storytelling contests to skill-based competitions that push your limits.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">What is the connection to Solana?</summary>
          <p class="mt-2">The Chosen One is a memecoin project built on the Solana blockchain, leveraging its speed, scalability, and low transaction costs to provide a seamless and enjoyable experience for our community.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">Why use Solana for a memecoin?</summary>
          <p class="mt-2">Solana's high-performance blockchain offers fast and affordable transactions, making it the perfect platform for a community-driven memecoin like The Chosen One.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">How can I acquire The Chosen One memecoin?</summary>
          <p class="mt-2">You can acquire The Chosen One memecoin through supported Solana wallets and decentralized exchanges like Raydium once the token is officially launched.</p>
        </details>
        <details class="bg-gray-700 p-4 rounded-lg">
          <summary class="text-xl font-semibold cursor-pointer">What makes The Chosen One different from other memecoins?</summary>
          <p class="mt-2">The Chosen One combines the fun and community spirit of memecoins with a meaningful narrative, creative challenges, and opportunities for collaboration, all powered by the Solana blockchain.</p>
        </details>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>